import "./Logo.css";

export const Logo = () => {
  return (
    <>
      <div className="logo-ring"></div>
      <p className="logo-text">OK</p>
    </>
  );
};
