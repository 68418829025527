import { Logo } from "../../components/logo/Logo";
import "./Home.css";

export const Home = () => {
  return (
    <div id="home" className="section-content section-border-bottom">
      <Logo />
      <h1>Osama Kashif</h1>
    </div>
  );
};
